<section class="animation-effects donation-form-section">
  <div class="row" *ngIf="!enableDonation">
    <div class="col-sm-12">
      <div class="well well-sm">
        <h4 class="text-danger">{{ "DONATIONS_NOT_ALLOWED" | translate }}</h4>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="gettingCampaign">
    <div class="col-sm-12">
      <div class="text-warning text-center">
        <i class="fa fa-spin fa-spinner" style="font-size: 50px"></i>
        <div style="padding-top: 10px">Getting Campaign</div>
      </div>
    </div>
  </div>

  <form
    #donateForm="ngForm"
    (ngSubmit)="toggleBrainTreeValidation(); save()"
    *ngIf="enableDonation && !gettingCampaign"
    #infoForm
  >
    <div class="row" style="padding-bottom: 20px" *ngIf="!drtv">
      <div class="col-sm-12">
        <img
          [src]="imageUrl"
          alt=""
          [hidden]="!imageUrl"
          id="campaignBannerImage"
          class="img-responsive"
          style="margin: 0 auto"
        />
      </div>
    </div>

    <div class="row">
      <app-gift-amount
      #giftAmount
      [language]="language"
      [gettingCampaign]="gettingCampaign"
      [donationAmounts]="donationAmounts"
      [transaction]="transaction"
      [donation]="donation"
      [form]="donateForm"
      [defaultDonationAmount]="defaultDonationAmount"
      [drtv]="drtv"
      [hidden]="drtv && transactionComplete"
      (onDonationAmountChange)="handleDonationChanged()"
    >
    </app-gift-amount>
    </div>

    <app-donor #donorForm
      [donorEditCommon]="donorEditCommon"
      [form]="donateForm"
      (onNameChange)="onNameChange()"
      [hidden]="drtv && transactionComplete"
    ></app-donor>

    <app-payment-details 
      [transaction]="transaction" 
      [form]="donateForm"
      [hidden]="drtv && transactionComplete"
      #paymentDetailsForm
    ></app-payment-details>

    <app-honor
      #honorForm
      [honorModel]="honorModel"
      [donorEditCommon]="donorEditCommon"
      [giftNotify]="giftNotify"
      [form]="donateForm"
      [hidden]="drtv"
    ></app-honor>

    <additional-info
      [hidden]="drtv"
      [donorEditCommon]="donorEditCommon">
    </additional-info>


    <re-captcha
    #captchaRef="reCaptcha" 
    (resolved)="resolved($event)"
    (error)="errored($event)" 
    [siteKey]="recaptchaSiteKey" 
    size="invisible">
  </re-captcha>

    <div class="col-xs-12">

    <div class="row" style="margin-bottom: 30px" 
      [hidden]="drtv && transactionComplete">
      <div class="col-xs-12 text-center">
        <!-- <button type="submit" hidden style="opacity: 0; pointer-events: none;"></button> -->
        <button
          style="margin: 0; white-space: normal"
          class="btn btn-primary donation-submit"
          type="submit"
          #completeGiftBtn
        >
          <!--
                    [disabled]="donateForm.form.invalid"
                    (click)="toggleBrainTreeValidation(); save();"
                  -->
          <span style="font-size: 18px" class="hidden-xs">
            {{ "SPLASH_COMPLETE_GIFT" | translate }} <i class="fa fa-check"></i>
          </span>
          <span style="font-size: 15px" class="visible-xs">
            {{ "SPLASH_COMPLETE_GIFT" | translate }} <i class="fa fa-check"></i>
          </span>
        </button>
      </div>
      <div class="col-xs-12 text-center">
        <div
          style="padding-bottom: 10px; padding-top: 15px"
          class="text-primary"
          [innerHTML]="'SPLASH_COMPLETE_GIFT_MESSAGE' | translate"
        ></div>
      </div>
    </div>
  </div>
  </form>
</section>
<app-modal
  [shown]="donorEditCommon.cities && donorEditCommon.cities.length > 1"
  [title]="'Select City'"
  (onClose)="handleCloseEditCityModal()"
  [onCloseText]="'CLOSE' | translate"
>
  <select class="form-control form-control-narrow" [(ngModel)]="selectedRecord">
    <option value="">--Select City--</option>
    <option
      *ngFor="let ctype of donorEditCommon.cities"
      [ngValue]="ctype.recordId"
    >
      {{ ctype.city }}
    </option>
  </select>
</app-modal>

<app-modal
  [shown]="showVerifyAddressModal"
  [title]="'VERIFY_ADDRESS' | translate"
  (onClose)="handleCloseVerifyAddressModal('')"
  [onCloseText]="'RETRY' | translate"
  (onContinue)="handleCloseVerifyAddressModal('ignore')"
  [onContinueText]="'IGNORE' | translate"
  (onAccept)="handleCloseVerifyAddressModal('accept')"
  [onAcceptText]="
    (donorEditCommon.melissaData.address1 ? 'ACCEPT' : '') | translate
  "
>
  <h3 [hidden]="donorEditCommon.melissaData.address1">
    {{ "MELISSA_GENERIC_ERROR_2" | translate }}
  </h3>
  <div [hidden]="!donorEditCommon.melissaData.address1">
    <table class="table table-striped">
      <tbody>
        <tr>
          <td>&nbsp;</td>
          <td>
            {{ "BEFORE" | translate }}
          </td>
          <td>{{ "AFTER" | translate }} (USPS)</td>
        </tr>
        <tr
          [ngClass]="
            rowClass(
              donorEditCommon.donor.address1,
              donorEditCommon.melissaData.address1
            )
          "
        >
          <td>{{ "ADDRESS_1" | translate }}</td>
          <td>
            {{ donorEditCommon.donor.address1 }}
          </td>
          <td>
            {{ donorEditCommon.melissaData.address1 }}
          </td>
        </tr>
        <tr
          [ngClass]="
            rowClass(
              donorEditCommon.donor.address2,
              donorEditCommon.melissaData.address2
            )
          "
        >
          <td>
            {{ "ADDRESS_2" | translate }}
          </td>
          <td>
            {{ donorEditCommon.donor.address2 }}
          </td>
          <td>
            {{ donorEditCommon.melissaData.address2 }}
          </td>
        </tr>
        <tr
          [ngClass]="
            rowClass(
              donorEditCommon.donor.city,
              donorEditCommon.melissaData.city
            )
          "
        >
          <td>
            {{ "CITY" | translate }}
          </td>
          <td>
            {{ donorEditCommon.donor.city }}
          </td>
          <td>
            {{ donorEditCommon.melissaData.city }}
          </td>
        </tr>
        <tr
          [ngClass]="
            rowClass(
              donorEditCommon.donor.state,
              donorEditCommon.melissaData.state
            )
          "
        >
          <td>
            {{ "STATE" | translate }}
          </td>
          <td>
            {{ donorEditCommon.donor.state }}
          </td>
          <td>
            {{ donorEditCommon.melissaData.state }}
          </td>
        </tr>
        <tr
          [ngClass]="
            rowClass(
              donorEditCommon.donor.postalCode,
              donorEditCommon.melissaData.postalCode
            )
          "
        >
          <td>
            {{ "POSTAL_CODE" | translate }}
          </td>
          <td>
            {{ donorEditCommon.donor.postalCode }}
          </td>
          <td>
            {{ donorEditCommon.melissaData.postalCode }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-modal>

<app-modal
  [shown]="showVerifyModal"
  [title]="'VERIFY_TITLE' | translate"
  (onClose)="handleCloseVerifyModal(false)"
  [onCloseText]="'REENTER_INFORMATION' | translate"
  (onSuccess)="handleCloseVerifyModal(true)"
  [onSuccessText]="'CONTINUE' | translate"
>
  <form #verifyCCForm="ngForm">
    <div class="row" style="text-align: left">
      <div class="col-sm-6" *ngIf="showVerifyCCModal">
        <div class="form-group">
          <strong>{{ "CREDIT_CARD_NO" | translate }}</strong>
          <div class="well well-sm" style="margin-top: 10px">
            <span>
              {{ wf1MaskAccount(transaction.creditCard) }}
            </span>
          </div>
        </div>
        <div class="form-group">
          <strong>{{ "CC_TYPE" | translate }}</strong>
          <div class="well well-sm" style="margin-top: 10px">
            {{ typeOut }}
          </div>
        </div>
        <div class="form-group">
          <strong>{{ "EXPIRATION_DATE" | translate }}</strong>
          <div class="well well-sm" style="margin-top: 10px">
            {{ transaction.expirationMonth }} / {{ transaction.expirationYear }}
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <strong>{{ "DONATION_FREQUENCY" | translate }}</strong>
          <div class="well well-sm" style="margin-top: 10px">
            {{ frequency }}
          </div>
        </div>
        <div class="form-group">
          <strong>{{ "AMOUNT" | translate }}</strong>
          <div class="well well-sm" style="margin-top: 10px; font-size: 18px">
            <strong>
              {{ transaction.donationAmount | currency }}
            </strong>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-modal>

<app-modal
  [shown]="showConfirmCreditCardECheckPaymentModal"
  [title]="'PAYMENT_CONFIRMATION_TITLE' | translate"
  (onClose)="handleCloseCreditCardECheckPaymentModal()"
  [onCloseText]="'CLOSE' | translate"
  size="md"
>
  <form #confirmCreditCardECheckPaymentModal="ngForm">
    <div class="row">
      <div
        class="col-sm-12"
        style="font-weight: bold; color: #93c54b; font-size: 18px"
      >
        <p>{{ "PAYMENT_CONFIRMATION_MSG1" | translate }}</p>
        <p>{{ "PAYMENT_CONFIRMATION_MSG2" | translate }}</p>
      </div>
    </div>

    <div class="row" style="text-align: left">
      <div class="col-sm-12">
        <div class="form-group">
          <p *ngIf="drtv">
            Thank you for your generous support today.
            {{
              donorEditCommon.donor.cmnhHospitalName ||
                "Your local children's hospital"
            }}
            will now be able to count on your gift to help kids in your
            community get access to the care they need.
          </p>
          <strong>{{ "DONATION_FREQUENCY" | translate }}</strong>
          <div class="well well-sm" style="margin-top: 10px">
            {{ frequency }}
          </div>
        </div>
        <div class="form-group">
          <strong>{{ "AMOUNT" | translate }}</strong>
          <div class="well well-sm" style="margin-top: 10px; font-size: 18px">
            <strong>
              {{ csr.donationAmount | currency }}
            </strong>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <strong>{{ "YOUR_REFERENCENO" | translate }}</strong>
          <div class="well well-sm" style="margin-top: 10px">
            {{ csr.authCode }}
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <i>{{ "PAYMENT_CONFIRMATION_STATEMENT" | translate }}</i>
      </div>
      <div class="col-sm-12" style="margin-top: 10px; font-size: 18px" [hidden]="!drtv">
        If you want to make another donation, please click 
        <a class="text-danger pointer" (click)="continueWithCall(true)">Here</a> or reload the page.
      </div>
    </div>
  </form>
</app-modal>

<app-modal
  [shown]="showFailedCCECheckModal"
  [title]="'FAILED_TRANSACTION_PAGE_TITLE' | translate"
  (onClose)="handleCloseFailedCCECheckModal()"
  [onCloseText]="'CLOSE' | translate"
>
  <form #failedCCECheckModal="ngForm">
    <div class="row" style="text-align: left">
      <div class="col-sm-12">
        <div class="form-group">
          <strong>{{ "RESPONSE" | translate }}</strong>
          <div class="well well-sm" style="margin-top: 10px">
            {{ csr.responseMsg }}
          </div>
        </div>
      </div>
    </div>
  </form>
</app-modal>

<app-modal
  [shown]="showVerifyeCheckPaymentModal"
  [title]="'VERIFY_ECHECK_TITLE' | translate"
  (onClose)="handleCloseVerifyeCheckPaymentModal()"
  [onCloseText]="'REENTER_INFORMATION' | translate"
  (onSuccess)="handleCloseVerifyeCheckPaymentModal(true)"
  [onSuccessText]="'CONTINUE' | translate"
>
  <form #verifyECheckForm="ngForm">
    <div class="row" style="text-align: left">
      <div class="col-sm-6">
        <div class="form-group">
          <strong>{{ "ACCOUNT" | translate }}</strong>
          <div class="well well-sm" style="margin-top: 10px">
            {{ transaction.abaNumber }} /
            <span>{{ wf1MaskAccount(transaction.bankAccountNumber) }}</span>
          </div>
        </div>
        <div class="form-group">
          <strong>{{ "BANK_NAME" | translate }}</strong>
          <div class="well well-sm" style="margin-top: 10px">
            {{ transaction.bankName }}
          </div>
        </div>
        <div class="form-group">
          <strong>{{ "ACCOUNT_TYPE" | translate }}</strong>
          <div class="well well-sm" style="margin-top: 10px">
            {{ "CHECKING" | translate }}
            <span *ngIf="transaction.isCheckingAccount">
              {{ "YES" | translate }}
            </span>
            <span *ngIf="!transaction.isCheckingAccount">
              {{ "NO" | translate }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <strong>{{ "DONATION_FREQUENCY" | translate }}</strong>
          <div class="well well-sm" style="margin-top: 10px">
            {{ frequency }}
          </div>
        </div>
        <div class="form-group">
          <strong>{{ "AMOUNT" | translate }}</strong>
          <div class="well well-sm" style="margin-top: 10px; font-size: 18px">
            <strong>
              {{ transaction.donationAmount | currency }}
            </strong>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p>
        By clicking Continue, I authorize Braintree, a service of PayPal, on
        behalf of Children's Miracle Network Hospitals (i) to verify my bank
        account information using bank information and consumer reports and (ii)
        to debit my bank account.
      </p>
    </div>
  </form>
</app-modal>
