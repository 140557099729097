<div [ngClass]="{ modal: true, shown: shown }">
  <div class="modal-dialog modal-{{size}}" role="document">
    <!-- <ng-content></ng-content> -->
    <div class="modal-content">
      <div class="modal-header" *ngIf="title || subTitle">
        <button
          (click)="handleClose()"
          type="button"
          class="close"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 *ngIf="title">{{ title }}</h4>
        <h5 *ngIf="subTitle">{{ subTitle }}</h5>
      </div>

      <div class="modal-body">
        <ng-content></ng-content>
      </div>

      <div class="modal-footer">
        <button
          *ngIf="onCloseText"
          (click)="handleClose()"
          type="button"
          class="btn btn-default close-button"
          data-dismiss="modal"
        >
          {{onCloseText}}
        </button>
        <button
          *ngIf="onSuccessText"
          (click)="handleSuccess()"
          type="button"
          class="btn btn-success success-button"
          data-dismiss="modal"
        >
          {{onSuccessText}}
        </button>
        <button
          *ngIf="onContinueText"
          (click)="handleContinue()"
          type="button"
          class="btn btn-warning continue-button"
          data-dismiss="modal"
        >
          {{onContinueText}}
        </button>
        <button
          *ngIf="onAcceptText"
          (click)="handleAccept()"
          type="button"
          class="btn btn-primary accept-button"
          data-dismiss="modal"
        >
          {{onAcceptText}}
        </button>
      </div>
    </div>
  </div>
</div>
